import React from "react";
import { Layout, Row, Col, Collapse } from "antd";
import Header from "../../components/common/header";
import { Helmet } from "react-helmet";
import CommonFooter from "../../components/common/footer";
import AboutSider from "../../components/about/about-sider";

import css from "../../styles/guide.css";

const Panel = Collapse.Panel;

class AboutContent extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="DayViewer Calendar Planner and Organizer, helping the world get organized. Task management with notes, contacts, journal and more."
            />
            <title>About DayViewer Planner</title>
          </Helmet>
          <Header />
          <Layout style={{ minHeight: "100vh" }}>
            <AboutSider />
            <Layout className="guide-body">
              <h1>About</h1>
              <Row>
                <Col>
                  <h3>
                    DayViewer is a place online that simplifies the process to
                    plan &amp; see what you have to do every day.
                  </h3>
                  <p>
                    DayViewer was created in order to help businesses and
                    professionals keep a track of and plan their days and other
                    information.
                  </p>
                  <p>
                    Sometimes a simple reminder and a clear view of your
                    schedule, tasks and other information that needs to be done
                    gives you the focus to get things done.
                  </p>

                  <p>
                    We set out to build an application which has the right blend
                    of productivity and planning tools to make a positive impact
                    on personal and business time &amp; task management.
                    DayViewer online calendar planner system is being used by
                    many business worldwide. It is designed to be easy to use
                    &amp; flexible enough to pick up straight from the moment
                    you sign up - so no need for any training.
                  </p>

                  <p>
                    The DayViewer concept was drawn up whilst running a small
                    business, at times, many tasks needed to be done each day.
                    There was no other online organizer application at the time
                    that was easy enough to pick up and use as well as have the
                    features required to be truly useful. DayViewer meets the
                    need to help plan &amp; see what needed doing on a daily
                    basis. DayViewer lets you plan your day, tackle your tasks
                    and reminds you of events.
                  </p>

                  <p>
                    The philosophy of DayViewer and our mission is quite simple
                    - in short we want DayViewer to help you achieve more. To
                    create a product that improves your quality of life and/or
                    help reduce daily work related stresses, increase your free
                    time, and to help you hit your professional or personal
                    goals.
                  </p>
                  <p>
                    {" "}
                    Use DayViewer to help you learn new skills by using the
                    notes organizer system and by planning your study time.
                    DayViewer becomes an invaluable personal assistant and
                    companion.
                  </p>

                  <p>
                    DayViewer now has a built in comprehensive team
                    collaboration system where a team can collaborate together
                    on a project, schedule jobs &amp; tasks, and keep
                    coordinated with events and other activities. Working within
                    one of our team rooms will give greater coordination &amp;
                    motivation to get your tasks done. Planning a schedule
                    around a team workspace reduces confusion and time wasted -
                    no more need for those unproductive progress meetings and
                    crazy "cc group/chain emails".
                  </p>

                  <p>
                    Try out Dayviewer and see how it works for you, we would
                    love to hear your feedback anytime- Thank you &amp; have a
                    great day
                  </p>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default AboutContent;
